.App {
  text-align: center;
  background-color: lightgray;
}

.Logo-left {
  height: 30%;
  float: left;
}

.Logo-right {
  height: 30%;
  float: right;
}

.Le-hr {
  margin-top: -450px;
}

.Text {
  margin: auto;
  width: 650px;
  margin-top: 50px;
}

.Text-error{
  margin: auto;
  width: 400px;
  margin-top: 50px;
  color:red;
}

.Button-cnx {
  margin: auto;
  width: 300px;
  margin-top:100px;
}

.Bottom-text {
  position: absolute;
  left: 20px;
  bottom: 0;
}

@media (max-device-width:768px) and (orientation: landscape) {
  html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }
}

@media (max-width: 640px) {
  .Logo-left {
    height: 10%;
    float: left;
  }

  .Logo-right {
    height: 10%;
    float: right;
  }

  .Le-hr {
    margin-top: -550px;
  }

  .Text {
    margin: auto;
    width: 350px;
    margin-top: 50px;
  }

  .Button-cnx {
    margin: auto;
    width: 200px;
    margin-top:100px;
  }

  .Bottom-text {
    position: absolute;
    left: 20px;
    bottom: 0;
  }

}



